

.pbdiv {
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    flex-direction: column;
}

.pbtable {
    background-color: rgb(223, 223, 223);

}

.pbtable > thead > tr > th {
    padding-left: 10px;
    text-align: start;
}

.pbtable > tbody > tr > td {
    padding-left: 10px;
}

.pbtable > tbody > tr > td > div {
    display: flex;
}

.ptable > tbody > tr:last-child > td:last-child {
  padding-right: 10px;
}